export default {
  restaurantListAvailable: 'restaurantListAvailable',
  geolocationAvailable: 'geolocationAvailable',
  autoDetectionRequested: 'autoDetectionRequested',
  restaurantSelected: 'restaurantSelected',
  restaurantTypeToggle: 'restaurantTypeToggle',
  mapInitDetailsAvailable: 'mapInitDetailsAvailable',
  travelModeSelected: 'travelModeSelected',
  directionsUpdated: 'directionsUpdated',
  warningNotification: 'warningNotification',
  infoNotification: 'infoNotification',
  needAddressfromLatitudeAndLongitude: 'needAddressfromLatitudeAndLongitude',
  gotAddressFromLatitudeAndLongitude: 'gotAddressFromLatitudeAndLongitude',
};
